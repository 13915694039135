import React from 'react'
import { graphql } from 'gatsby'
import $ from '@gizt/selector'

import Layout from '../../components/layout'
import Container from '../../components/container'
import PageTitle from '../../components/page-title'
import InfoSubNavigation from '../../components/info-sub-navigation'
import MetaTitle from '../../components/meta-title'
import MetaDescription from '../../components/meta-description'

export default function GraphicDesignPage ({ data: queryData }) {
  const images = $('images.edges[].node.childImageSharp.*', queryData)

  return (
    <Layout>
      <MetaTitle>Ontwerpen advertentie</MetaTitle>
      <MetaDescription>
        Op maat ontwerpen van advertenties en banners. Zet jouw stal of paard in
        de kijker.
      </MetaDescription>

      <div className='container-wide pt-40'>
        <PageTitle className='mb-40'>Info</PageTitle>
        <InfoSubNavigation />
      </div>

      <Container className='pb-40'>
        <p className='max-w-600 my-16'>
          Je hebt mooie foto’s, maar je wilt er graag iets meer mee doen?
        </p>

        <p className='max-w-600 my-16'>
          Niets beter dan een mooie advertentie om je stal en/of paarden in de
          kijker te zetten.
          <br />
          De advertentie wordt geheel op maat ontworpen, waarbij ik rekening
          houd met je huisstijl en persoonlijke voorkeuren. Ook het ontwerpen
          van flyers, banners en logo’s is mogelijk.
        </p>

        <p className='max-w-600 my-16'>
          De uitwerking kan erg uiteenlopend zijn, de prijs staat dus ook niet
          vast. Deze varieert van €50 voor eerder simpele ontwerpen, tot €125
          voor complexe advertenties.
          <br />
          Contacteer me vrijblijvend om het eens te bespreken.
        </p>

        <div className='mt-80 md:flex md:flex-wrap justify-around -mx-8'>
          {images.map(image => {
            return (
              <div className='text-center md:w-1/2 lg:w-1/4 p-8'>
                <img className='w-auto' src={image.resize.src} alt='' />
              </div>
            )
          })}
        </div>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    images: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativeDirectory: { eq: "info/graphic-design" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            id
            resize(height: 560) {
              src
            }
          }
        }
      }
    }
  }
`
