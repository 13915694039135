import React from 'react'

import SubNavigation from '../sub-navigation'

const navigationItems = [
  {
    id: 'photoshoots',
    title: 'Fotoreportage',
    url: '/info/fotoreportage'
  },
  {
    id: 'graphic-design',
    title: 'Grafisch ontwerp',
    url: '/info/grafisch-ontwerp'
  }
]

export default function InfoSubNavigation () {
  return <SubNavigation className='mt-0 mb-56' menuItems={navigationItems} />
}
